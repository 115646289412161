
import {
  defineComponent, reactive, watch,
} from 'vue';
import { useTelaBase } from '@/core/composables/TelaBase';
import { useModalBase } from '@/core/composables/ModalBase';
import { useGradeBase } from '@/core/composables/GradeBase';
import Icone from '@/core/components/Icone.vue';
import Card from '@/core/components/Tela/Card.vue';
import PreviewPdf from '@/core/components/Modal/PreviewPdf.vue';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import storeSistema from '@/store/storeSistema';
import UtilitarioData from '@/core/utilitarios/UtilitarioData';
import UtilitarioMascara from '@/core/utilitarios/UtilitarioMascara';
import { IDTOTituloFinanceiro } from '@/models/DTO/Financeiro/TitulosFinanceiros/IDTOTituloFinanceiro';
import { EStatusTituloFinanceiro } from '@/models/Enumeradores/Financeiro/EStatusTituloFinanceiro';
import { EStatusRetornoBancario } from '@/models/Enumeradores/Financeiro/EStatusRetornoBancario';
import RequisicaoModal from '@/core/components/Modal/RequisicaoModal.vue';
import { ISalvamentoAutomatico } from '@/models/Entidades/MeuSistema/ISalvamentoAutomatico';
import ServicoSistema from '@/servicos/MeuSistema/ServicoSistema';
import { ECustomRenderRow } from '@/core/models/AntDesign/IColumn';
import { IArquivoPdf } from '@/core/models/IArquivoPdf';
import ServicoImportacaoFinanceiro from '@/servicos/ImportacoesRegistros/ServicoImportacaoFinanceiro';
import SelecionarTipoDocumentoFinanceiro from '@/components/Cadastros/Financeiro/SelecionarTipoDocumentoFinanceiro.vue';
import SelecionarPessoa from '@/components/Cadastros/Pessoas/SelecionarPessoa.vue';
import SelecionarConta from '@/components/Cadastros/Financeiro/SelecionarConta.vue';
import SelecionarCategoriaPlanoConta from '@/components/Cadastros/PlanosContas/SelecionarCategoriaPlanoConta.vue';
import { IRetornoRequisicao } from '@/core/models/IRetornoRequisicao';
import ServicoParametros from '@/servicos/MeuSistema/ServicoParametros';
import { useTituloFinanceiroBase } from '@/composables/Financeiro/TituloFinanceiroBase';

export default defineComponent({
  name: 'ImportacaoRegistrosExcelModal',
  props: {
    visivel: {
      type: Boolean,
      default: false,
    },
    empresa: {
      type: Number,
      required: true,
    },
    tipoTitulo: {
      type: Number,
      required: true,
    },
  },
  components: {
    Icone,
    Card,
    RequisicaoModal,
    PreviewPdf,
    SelecionarTipoDocumentoFinanceiro,
    SelecionarPessoa,
    SelecionarConta,
    SelecionarCategoriaPlanoConta,
  },
  emits: ['update:visivel', 'confirmacao'],
  setup(props, { emit }) {
    const {
      telaBase, apresentarMensagemAlerta, apresentarMensagemSucesso, apresentarMensagemErro,
    } = useTelaBase();
    const { modalBase } = useModalBase(props, emit);
    const { gradeBase } = useGradeBase();
    const servicoSistema = new ServicoSistema();
    const servicoImportacaoFinanceiro = new ServicoImportacaoFinanceiro();
    const servicoParametros = new ServicoParametros();
    servicoImportacaoFinanceiro.requisicaoSistema();
    servicoParametros.requisicaoSistema();

    const { obterClasseStatus } = useTituloFinanceiroBase(props);

    const state = reactive({
      titulos: [] as IDTOTituloFinanceiro[],
      cacheAutomatico: {} as ISalvamentoAutomatico,
      cacheDisponivel: false,
      nomeArquivo: '',
      buscaRapida: '',
      enviandoArquivo: false,
      painelUpload: 1,
      arquivosPdf: [] as IArquivoPdf[],
      codigotipoDocumentoSelecionado: 0,
      codigoPessoa: 0,
      codigoConta: 0,
      codigoCategoria: 0,
      descricaoTipoDocumentoSelecionado: '',
      nomePessoa: '',
      descricaoConta: '',
      nomeCategoria: '',
      controleAlcada: false,
    });

    function preencherColunas() {
      if (storeSistema.state.layoutMobile) {
        gradeBase.colunas = [
          {
            title: 'Index', dataIndex: 'index', key: 'Index', position: 1, visible: false,
          },
          {
            title: 'Título', dataIndex: 'titulo', key: 'TituloFinanceiro', position: 2, visible: true,
          },
        ];
      } else {
        gradeBase.colunas = [
          {
            title: 'Index', dataIndex: 'index', key: 'Index', position: 1, visible: false,
          },
          {
            title: 'N.Identificação', dataIndex: 'numeroTitulo', key: 'NumeroTitulo', position: 2, visible: true, ellipsis: true, width: 100,
          },
          {
            title: 'Tp Documento', dataIndex: 'descricaoTipoDocumentoFinanceiro', key: 'DescricaoTipoDocumentoFinanceiro', position: 3, visible: true, width: 100, ellipsis: true, align: 'center',
          },
          {
            title: 'Status', dataIndex: 'descricaoStatus', key: 'Status', position: 4, visible: true, ordering: true, ellipsis: true, width: 150, align: 'left',
          },
          {
            title: 'Vencimento', dataIndex: 'dataVencimento', key: 'DataVencimento', customRenderRow: ECustomRenderRow.MascararData, position: 5, visible: true, ordering: false, width: 85, align: 'center',
          },
          {
            title: 'Competência', dataIndex: 'dataCompetencia', key: 'DataCompetencia', customRenderRow: ECustomRenderRow.MascararData, position: 6, visible: true, ordering: false, width: 90, align: 'center',
          },
          {
            title: 'Baixa', dataIndex: 'dataBaixa', key: 'DataBaixa', customRenderRow: ECustomRenderRow.MascararData, position: 7, visible: true, ordering: false, width: 95, align: 'center',
          },
          {
            title: 'CPF/CNPJ', dataIndex: 'cpfCnpjPessoa', key: 'CpfCnpjPessoa', position: 8, visible: true, ellipsis: true, width: 150, customRenderRow: ECustomRenderRow.MascararCPFCNPJ,
          },
          {
            title: 'Pessoa', dataIndex: 'nomePessoa', key: 'NomePessoa', position: 9, visible: true, ellipsis: true, width: 150,
          },
          {
            title: 'Conta', dataIndex: 'descricaoConta', key: 'Conta', position: 10, visible: true, ellipsis: true, width: 150,
          },
          {
            title: 'Categoria', dataIndex: 'nomePlanoContaCategoria', key: 'NomePlanoContaCategoria', position: 11, visible: true, ordering: true, width: 150,
          },
          {
            title: 'Valor R$', dataIndex: 'valorTotal', key: 'TalorTotal', position: 12, visible: true, customRenderRow: ECustomRenderRow.MascararFracionado, align: 'right', width: 80,
          },
          {
            title: 'Juros/Multa R$', dataIndex: 'jurosMulta', key: 'JurosMulta', position: 13, visible: true, customRenderRow: ECustomRenderRow.MascararFracionado, align: 'right', width: 100,
          },
          {
            title: 'Acréscimo R$', dataIndex: 'acrescimo', key: 'Acrescimo', position: 14, visible: true, customRenderRow: ECustomRenderRow.MascararFracionado, align: 'right', width: 90,
          },
          {
            title: 'Desconto R$', dataIndex: 'desconto', key: 'Desconto', position: 15, visible: true, customRenderRow: ECustomRenderRow.MascararFracionado, align: 'right', width: 85,
          },
          {
            title: 'Saldo R$', dataIndex: 'saldo', key: 'Saldo', position: 16, visible: true, customRenderRow: ECustomRenderRow.MascararFracionado, align: 'right', width: 85,
          },
        ];
      }
    }

    async function limparTela() {
      state.titulos = [] as IDTOTituloFinanceiro[];
      gradeBase.codigosSelecionados = [] as number[];
      state.nomeArquivo = '';
      state.buscaRapida = '';
      state.enviandoArquivo = false;
      state.painelUpload = 1;
      state.codigotipoDocumentoSelecionado = 0;
      state.codigoPessoa = 0;
      state.codigoConta = 0;
      state.codigoCategoria = 0;
      state.descricaoTipoDocumentoSelecionado = '';
      state.nomePessoa = '';
      state.descricaoConta = '';
      state.nomeCategoria = '';
      preencherColunas();
    }

    function obterIdentificadorCache(): string {
      return `IMPORTACAO_TITULOS_FINANCEIROS_EXCEL_${storeSistema.getters.codigoUsuarioAutenticado()}`;
    }

    function salvarCache() {
      const identificador = obterIdentificadorCache();
      state.cacheAutomatico.data = UtilitarioData.obterDataAtual();
      state.cacheAutomatico.codigoEmpresa = props.empresa;
      state.cacheAutomatico.codigoUsuario = storeSistema.getters.codigoUsuarioAutenticado();
      state.cacheAutomatico.identificador = telaBase.identificadorRecurso;
      state.cacheAutomatico.dados = JSON.stringify(state.titulos);
      servicoSistema.salvamentoAutomaticoLocalStorage(`${identificador}`, state.cacheAutomatico);
      state.cacheDisponivel = false;
    }

    async function recuperarCache() {
      if (UtilitarioGeral.valorValido(state.cacheAutomatico.dados)) {
        const cache = JSON.parse(state.cacheAutomatico.dados);
        if (UtilitarioGeral.objetoValido(cache)) {
          servicoSistema.removerSalvamentoAutomaticoLocalStorage(obterIdentificadorCache());
          state.titulos = cache;
          state.cacheDisponivel = false;
          state.painelUpload = 0;
          apresentarMensagemSucesso('Informações recuperadas com sucesso!');
        }
      }
    }

    async function verificarCache() {
      const identificador = obterIdentificadorCache();
      const salvamentoAutomaticoLocalStorage = await servicoSistema.obterSalvamentoAutomaticoLocalStorage(identificador);
      if (UtilitarioGeral.objetoValido(salvamentoAutomaticoLocalStorage)) {
        state.cacheAutomatico = salvamentoAutomaticoLocalStorage;
        if (UtilitarioData.validaDataPreenchida(state.cacheAutomatico.data)) {
          const cache = JSON.parse(state.cacheAutomatico.dados);
          state.cacheAutomatico = cache;
          state.cacheDisponivel = true;
        }
      }
    }

    function selecionarArquivoUpload() {
      const elemento = document.getElementById('selecionarArquivo');
      if (elemento !== null) {
        elemento.click();
      }
    }

    const mudarSelecao = (selectedRowKeys: any) => {
      gradeBase.codigosSelecionados = selectedRowKeys;
    };

    async function enviarArquivoLeitura(arquivo: any) {
      limparTela();
      state.enviandoArquivo = true;
      const retorno = await servicoImportacaoFinanceiro.lerArquivoTitulosFinanceiros(props.empresa, props.tipoTitulo, arquivo);
      state.nomeArquivo = arquivo.name;
      if (UtilitarioGeral.validaLista(retorno)) {
        if (!UtilitarioGeral.validaLista(retorno)) {
          apresentarMensagemAlerta('Não foi possível ler o arquivo selecionado!');
        } else {
          state.titulos = retorno;
          state.titulos.forEach((t, index) => {
            state.titulos[index].index = index;
          });
          if (props.tipoTitulo === 1 && state.controleAlcada) {
            state.titulos.forEach((t, index) => {
              if (t.status === EStatusTituloFinanceiro.Pendente) {
                state.titulos[index].status = EStatusTituloFinanceiro.AprovacaoPendente;
                state.titulos[index].descricaoStatus = 'Aprovação Pendente';
              }
            });
          }
          state.painelUpload = 0;
        }
      } else {
        apresentarMensagemAlerta('Ocorreu um erro ao ler o arquivo');
      }
      state.enviandoArquivo = false;
    }

    async function uploadArquivoSelecionado(event: any) {
      await enviarArquivoLeitura(event.target.files[0]);
    }

    async function uploadArquivoArrastado(event: any) {
      await enviarArquivoLeitura(event.dataTransfer.files[0]);
    }

    function verificarFiltro(titulo: IDTOTituloFinanceiro) {
      let verificacao = false;
      if (state.buscaRapida === '' || state.buscaRapida === undefined) {
        return true;
      }

      if (UtilitarioGeral.valorValido(titulo.numeroTitulo) && titulo.numeroTitulo.toLowerCase().includes(state.buscaRapida)) {
        verificacao = true;
      }

      if (UtilitarioGeral.valorValido(titulo.descricaoTipoDocumentoFinanceiro) && titulo.descricaoTipoDocumentoFinanceiro.toLowerCase().includes(state.buscaRapida)) {
        verificacao = true;
      }

      if (UtilitarioGeral.valorValido(titulo.nomePessoa) && titulo.nomePessoa.toLowerCase().includes(state.buscaRapida)) {
        verificacao = true;
      }

      if (UtilitarioGeral.valorValido(titulo.descricaoConta) && titulo.descricaoConta.toLowerCase().includes(state.buscaRapida)) {
        verificacao = true;
      }

      if (UtilitarioGeral.valorValido(titulo.nomePlanoContaCategoria) && titulo.nomePlanoContaCategoria.toLowerCase().includes(state.buscaRapida)) {
        verificacao = true;
      }

      if (String(titulo.valorTotal) === state.buscaRapida) {
        verificacao = true;
      }

      if (String(titulo.saldo) === state.buscaRapida) {
        verificacao = true;
      }

      if (UtilitarioGeral.valorValido(titulo.descricaoStatus) && titulo.descricaoStatus.toLowerCase().includes(state.buscaRapida)) {
        verificacao = true;
      }
      return verificacao;
    }

    function alterarTiposDocumento() {
      state.titulos.forEach((t, index) => {
        if (gradeBase.codigosSelecionados.includes(index)) {
          state.titulos[index].codigoTipoDocumentoFinanceiro = state.codigotipoDocumentoSelecionado;
          state.titulos[index].descricaoTipoDocumentoFinanceiro = state.descricaoTipoDocumentoSelecionado;
        }
      });
    }

    function alterarPessoas() {
      state.titulos.forEach((t, index) => {
        if (gradeBase.codigosSelecionados.includes(index)) {
          state.titulos[index].codigoPessoa = state.codigoPessoa;
          state.titulos[index].nomePessoa = state.nomePessoa;
        }
      });
    }

    function alterarContas() {
      state.titulos.forEach((t, index) => {
        if (gradeBase.codigosSelecionados.includes(index)) {
          state.titulos[index].codigoConta = state.codigoConta;
          state.titulos[index].descricaoConta = state.descricaoConta;
        }
      });
    }

    function alterarCategorias() {
      state.titulos.forEach((t, index) => {
        if (gradeBase.codigosSelecionados.includes(index)) {
          state.titulos[index].codigoPlanoContaCategoria = state.codigoCategoria;
          state.titulos[index].nomePlanoContaCategoria = state.nomeCategoria;
        }
      });
    }

    function realizarAjustes() {
      if (gradeBase.codigosSelecionados.length === 0) {
        apresentarMensagemAlerta('nenhum título selecionado!');
        return;
      }

      if (state.codigotipoDocumentoSelecionado > 0) {
        alterarTiposDocumento();
      }

      if (state.codigoPessoa > 0) {
        alterarPessoas();
      }

      if (state.codigoConta > 0) {
        alterarContas();
      }

      if (state.codigoCategoria > 0) {
        alterarCategorias();
      }

      state.codigotipoDocumentoSelecionado = 0;
      state.codigoPessoa = 0;
      state.codigoConta = 0;
      state.codigoCategoria = 0;
      gradeBase.codigosSelecionados = [] as number[];
    }

    function validarTitulos() {
      let retorno = true;

      state.titulos.forEach((t, index) => {
        if (gradeBase.codigosSelecionados.includes(index)) {
          if (!UtilitarioGeral.valorValido(t.numeroTitulo) && retorno) {
            apresentarMensagemAlerta('Um dos títulos está com o número do título inválido!');
            retorno = false;
          }

          if (!UtilitarioData.validaDataPreenchida(t.dataVencimento) && retorno) {
            apresentarMensagemAlerta('Um dos títulos está com a data inválida!');
            retorno = false;
          }

          if (!UtilitarioGeral.validaCodigo(t.codigoTipoDocumentoFinanceiro) && retorno) {
            apresentarMensagemAlerta('Um dos títulos está o tipo de documento inválido!');
            retorno = false;
          }

          if (!UtilitarioGeral.validaCodigo(t.codigoPessoa) && retorno) {
            apresentarMensagemAlerta('Um dos títulos está a pessoa inválida!');
            retorno = false;
          }

          if (!UtilitarioGeral.validaCodigo(t.codigoConta) && retorno) {
            apresentarMensagemAlerta('Um dos títulos está a conta inválida!');
            retorno = false;
          }

          if (!UtilitarioGeral.validaCodigo(t.codigoPlanoContaCategoria) && retorno) {
            apresentarMensagemAlerta('Um dos títulos está a categoria inválida!');
            retorno = false;
          }

          if (t.valorTotal === 0 && retorno) {
            apresentarMensagemAlerta('Um dos títulos está o valor zerado!');
            retorno = false;
          }
        }
      });
      return retorno;
    }

    async function baixarArquivo() {
      const retorno = await servicoImportacaoFinanceiro.obterArquivoPadraoTitulosFinanceiros();
      if (UtilitarioGeral.valorValido(retorno)) {
        UtilitarioGeral.downloadArquivo(retorno);
      } else {
        apresentarMensagemErro('Ocorreu um erro ao obter a planilha de exemplo!');
      }
    }

    async function importarTitulos() {
      if (gradeBase.codigosSelecionados.length === 0) {
        apresentarMensagemAlerta('nenhum título selecionado!');
        return;
      }

      if (!validarTitulos()) {
        return;
      }

      const titulosSelecionados = [] as IDTOTituloFinanceiro[];
      state.titulos.forEach((t, index) => {
        if (gradeBase.codigosSelecionados.includes(index)) {
          titulosSelecionados.push(t);
        }
      });

      servicoImportacaoFinanceiro.importarTitulosFinanceiros(props.empresa, titulosSelecionados);
      modalBase.retornoRequisicao = {} as IRetornoRequisicao;
      modalBase.retornoRequisicao.mensagem = 'Os títulos serão importados em segundo plano assim que a importação for finalizada você será notificado!';
      modalBase.exibirRequisicaoModal = true;
      emit('confirmacao');
      modalBase.computedVisivel = false;
    }

    async function verificarControleAlcada() {
      const parametroValor = await servicoParametros.obterValor(props.empresa, 'PAR_FIN_ALCADA_DESPESAS');
      return parametroValor.valor === 'true';
    }

    watch(async () => modalBase.computedVisivel, async () => {
      limparTela();
      telaBase.carregando = true;
      if (modalBase.computedVisivel) {
        verificarCache();
        state.controleAlcada = await verificarControleAlcada();
      }
      telaBase.carregando = false;
    });

    return {
      props,
      state,
      emit,
      telaBase,
      modalBase,
      gradeBase,
      storeSistema,
      window,
      EStatusRetornoBancario,
      EStatusTituloFinanceiro,
      UtilitarioData,
      UtilitarioMascara,
      UtilitarioGeral,
      mudarSelecao,
      selecionarArquivoUpload,
      uploadArquivoSelecionado,
      salvarCache,
      uploadArquivoArrastado,
      recuperarCache,
      verificarFiltro,
      importarTitulos,
      baixarArquivo,
      realizarAjustes,
      obterClasseStatus,
      ECustomRenderRow,
    };
  },
});
