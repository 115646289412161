
import {
  defineComponent, onBeforeMount, reactive, ref, watch,
} from 'vue';
import { Modal } from 'ant-design-vue';
import { useRoute } from 'vue-router';
import { useTelaBase } from '@/core/composables/TelaBase';
import { useTituloFinanceiroBase } from '@/composables/Financeiro/TituloFinanceiroBase';
import Icone from '@/core/components/Icone.vue';
import PreviewPdf from '@/core/components/Modal/PreviewPdf.vue';
import TituloPadrao from '@/core/components/Tela/TituloPadrao.vue';
import PreferenciasColuna from '@/core/components/Preferencias/ColunaTabela.vue';
import MensagemSemDados from '@/core/components/Tela/MensagemSemDados.vue';
import BuscaGeral from '@/core/components/BuscaAvancada/BuscaGeral.vue';
import { ITelaOperacao } from '@/core/models/ITelaOperacao';
import { EStatusRetornoRequisicao } from '@/core/models/IRetornoRequisicao';
import { IListaPaginadaMetaData } from '@/core/models/Consulta/IListaPaginada';
import { ECustomRenderRow } from '@/core/models/AntDesign/IColumn';
import { IPagination } from '@/core/models/AntDesign/IPagination';
import { ETipoPermissao } from '@/models/Enumeradores/MeuSistema/Usuarios/ETipoPermissao';
import { EPermissaoDados } from '@/models/Enumeradores/MeuSistema/Usuarios/EPermissaoDados';
import { EVinculoEmpresa } from '@/core/models/Enumeradores/EEVinculoEmpresa';
import { ETipoArquivo } from '@/core/models/Enumeradores/ETipoArquivo';
import storeSistema from '@/store/storeSistema';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import UtilitarioMascara from '@/core/utilitarios/UtilitarioMascara';
import { useGradeBase } from '@/core/composables/GradeBase';
import UtilitarioData from '@/core/utilitarios/UtilitarioData';
import ContaReceberModal from './ContaReceberModal.vue';
import Card from '@/core/components/Tela/Card.vue';
import TituloFinanceiroTotalizadores from '@/components/Financeiro/TitulosFinanceiros/TituloFinanceiroTotalizadores.vue';
import { ETipoTituloFinanceiro } from '@/models/Enumeradores/Financeiro/ETipoTituloFinanceiro';
import { EStatusTituloFinanceiro } from '@/models/Enumeradores/Financeiro/EStatusTituloFinanceiro';
import { IDTOTituloFinanceiro } from '@/models/DTO/Financeiro/TitulosFinanceiros/IDTOTituloFinanceiro';
import CalendarioEventos from '@/core/components/Tela/CalendarioEventos.vue';
import { IFiltroGenericoAdicionado } from '@/core/models/BuscaAvancada/IFiltroGenericoAdicionado';
import { IFiltroGenerico } from '@/core/models/BuscaAvancada/IFiltroGenerico';
import { EOperadorLogico } from '@/core/models/Enumeradores/EOperadorLogico';
import ServicoTituloFinanceiroReceber from '@/servicos/Financeiro/ServicoTituloFinanceiroReceber';
import router from '@/router';
import UtilitarioDispositivo from '@/core/utilitarios/UtilitarioDispositivo';
import ServicoSistema from '@/servicos/MeuSistema/ServicoSistema';
import { IParametrosConsultaTituloFinanceiro } from '@/models/ParametrosRequisicao/Financeiro/IParametrosConsultaTituloFinanceiro';
import { IDTOUsuarioPermissoes } from '@/models/DTO/MeuSistema/Usuarios/IDTOUsuarioPermissoes';
import storeFinanceiro from '@/store/storeFinanceiro';
import { EMeioPagamento } from '@/models/Enumeradores/Cadastros/Financeiro/EMeioPagamento';
import ServicoBoletos from '@/servicos/Financeiro/ServicoBoleto';
import { ETipoEmissaoBoleto } from '@/models/Enumeradores/Financeiro/ETipoEmissaoBoletos';
import { IArquivoPdf } from '@/core/models/IArquivoPdf';
import ImportacaoRegistrosExcelModal from '@/components/Financeiro/TitulosFinanceiros/ImportacaoRegistrosExcelModal.vue';
import EmissaoNFSeTituloFinanceiroModal from '../../PrestacaoServicos/EmissaoNFSeTituloFinanceiroModal.vue';
import { ESituacaoBoleto } from '@/models/Enumeradores/Financeiro/ESituacaoBoleto';
import Totalizador from '@/core/components/Tela/Totalizador.vue';

export default defineComponent({
  name: 'ContaReceber',
  components: {
    Icone,
    Card,
    MensagemSemDados,
    BuscaGeral,
    TituloFinanceiroTotalizadores,
    ContaReceberModal,
    CalendarioEventos,
    TituloPadrao,
    PreferenciasColuna,
    PreviewPdf,
    ImportacaoRegistrosExcelModal,
    EmissaoNFSeTituloFinanceiroModal,
  },
  setup(props) {
    const route = useRoute();
    const servicoTituloFinanceiroReceber = new ServicoTituloFinanceiroReceber();
    const servicoSistema = new ServicoSistema();
    const servicoBoletos = new ServicoBoletos();
    const refTotalizadores = ref<InstanceType<typeof TituloFinanceiroTotalizadores>>();
    const {
      telaBase, obterPermissoes, preencherEmpresasDisponiveis, verificaConceitoParaApresentarEmpresas,
      defineEmpresasSelecionadasComPermissao, filtrarPermissaoDadosUsuarioMultiEmpresas,
      apresentarMensagemSucesso, apresentarMensagemAlerta, apresentarMensagemErro,
      adicionarAtalho, removerAtalho, preencherDadosRota, salvarPreferenciasFiltros, salvarPreferenciasOrdenacao,
      carregarPreferenciasFiltros, carregarPreferenciasOrdenacao, carregarPreferenciasGrade, salvarPreferenciasGrade,
    } = useTelaBase();

    const { gradeBase, redefinirPersonalizacaoColunas } = useGradeBase();

    const {
      tituloFinanceiroBase, obterClasseStatus, popularDadosEventos, adicionaFiltroVencimentoHoje,
      adicionaFiltroVencimentoEsteMes, adicionaFiltroVencimentoAtrasados, adicionaFiltroBaixadosHoje,
      adicionaFiltroBaixadosEsteMes, adicionaFiltroBaixadosAtrasados, obtemValorTitulo,
    } = useTituloFinanceiroBase(props);

    const state = reactive({
      empresaSelecionada: 0,
      exibirPersonalizarColunas: false,
      paginacao: {} as IPagination,
      paginacaoApi: { totalRegistros: 0, paginaAtual: 1 } as IListaPaginadaMetaData,
      timerRegistros: 0,
      qtdBoletosSelecionados: 0,
      qtdBoletosGeradosSelecionados: 0,
      qtdBoletosRegistradosSelecionados: 0,
      qtdGerarNfse: 0,
      gerandoBoletos: false,
      totalRegistrosComMascara: '100',
      exibirLancamento: false,
      exibirImportacaoExcel: false,
      exibirEmissaoNFSe: false,
      operacaoLancamento: {} as ITelaOperacao,
      carregandoCalendario: false,
      filtrosTotalizadores: [] as IFiltroGenerico[],
      autorizacoes: [] as IDTOUsuarioPermissoes[],
      titulosNFSe: [] as IDTOTituloFinanceiro[],
      arquivosPdf: [] as IArquivoPdf[],
      totalizadorSaldo: 0,
      totalSelecionado: 0,
    });

    telaBase.apresentarEmpresas = false;
    function preencherColunas() {
      gradeBase.colunasMobile = [
        {
          title: 'Código', dataIndex: 'codigo', key: 'Codigo', position: 1, visible: false,
        },
        {
          title: 'Título', dataIndex: 'titulo', key: 'TituloFinanceiro', position: 2, visible: true,
        },
      ];

      gradeBase.colunasPadrao = [
        {
          title: 'Código', dataIndex: 'codigoTituloFinanceiro', key: 'Codigo', position: 1, visible: false,
        },
        {
          title: 'T.Documento', dataIndex: 'tipoDocumentoFinanceiro', key: 'TipoDocumentoFinanceiro', position: 2, visible: true, width: 100, ellipsis: true, align: 'center', fixed: 'left',
        },
        {
          title: 'N.Identificação', dataIndex: 'numeroTitulo', key: 'NumeroTitulo', position: 3, visible: true, ellipsis: true, customRenderRow: ECustomRenderRow.LinkAcao, width: 75, fixed: 'left',
        },
        {
          title: 'Valor R$', dataIndex: 'valor', key: 'Valor', position: 4, visible: true, customRenderRow: ECustomRenderRow.MascararFracionado, fixed: 'left', align: 'right', width: 80,
        },
        {
          title: 'Status', dataIndex: 'descricaoStatus', key: 'Status', position: 5, visible: true, ordering: true, ellipsis: true, width: 100, align: 'left',
        },
        {
          title: 'Pessoa', dataIndex: 'nomePessoa', key: 'NomePessoa', position: 6, visible: true, ellipsis: true, width: 150,
        },
        {
          title: 'Vencimento', dataIndex: 'dataVencimentoFormatada', key: 'DataVencimento', position: 7, visible: true, ordering: false, width: 70, align: 'center',
        },
        {
          title: 'Competência', dataIndex: 'dataCompetenciaFormatada', key: 'DataCompetencia', position: 8, visible: true, ordering: false, width: 70, align: 'center',
        },
        {
          title: 'Baixa', dataIndex: 'dataBaixaFormatada', key: 'DataBaixa', position: 9, visible: true, ordering: false, width: 70, align: 'center',
        },
        {
          title: 'Conta', dataIndex: 'descricaoConta', key: 'Conta', position: 10, visible: true, ellipsis: true, width: 150,
        },
        {
          title: 'Categoria', dataIndex: 'nomePlanoContaCategoria', key: 'NomePlanoContaCategoria', position: 11, visible: true, ellipsis: true, width: 150,
        },
        {
          title: 'Doc.Fiscal', dataIndex: 'numeroDocumentoFiscal', key: 'NumeroDocumentoFiscal', position: 12, visible: true, ordering: true, width: 65, align: 'right',
        },
        {
          title: 'Observações', dataIndex: 'observacoes', key: 'Observacoes', position: 13, visible: true, ellipsis: true, width: 150,
        },
      ];
    }

    async function preencherEventos() {
      const dataInicial = UtilitarioData.obterPrimeiroDiaMes(tituloFinanceiroBase.dataCalendario);
      const dataFinal = UtilitarioData.obterUltimoDiaMes(tituloFinanceiroBase.dataCalendario);
      tituloFinanceiroBase.titulosCalendario = await servicoTituloFinanceiroReceber.ObterTitulosCalendario(state.empresaSelecionada, dataInicial, dataFinal);
      popularDadosEventos();
    }

    async function buscarTitulos(limparSelecionados: boolean) {
      tituloFinanceiroBase.titulos = [];
      if (limparSelecionados) gradeBase.codigosSelecionados = [];
      gradeBase.filtrosAplicados = [];

      if (UtilitarioGeral.validaLista(state.filtrosTotalizadores)) {
        gradeBase.filtrosAplicados = state.filtrosTotalizadores;
        state.filtrosTotalizadores = [];
      } else {
        tituloFinanceiroBase.buscaAvancada.filtrosAdicionados.forEach((item) => {
          gradeBase.filtrosAplicados.push(item.filtro);
        });
      }

      const parametrosConsulta = {} as IParametrosConsultaTituloFinanceiro;
      parametrosConsulta.empresas = [state.empresaSelecionada];
      parametrosConsulta.numeroPagina = state.paginacao.current;
      parametrosConsulta.qtdeRegistrosPagina = state.paginacao.pageSize;
      parametrosConsulta.qtdeRegistrosTotal = state.paginacao.total;
      parametrosConsulta.valorBuscaRapida = tituloFinanceiroBase.buscaRapida;
      parametrosConsulta.ordenacao = Array<string>();

      telaBase.ordenacaoSelecionada.forEach((ordenacao) => {
        parametrosConsulta.ordenacao.push(`${ordenacao.identificador}|${ordenacao.ordem}`);
      });

      if (!UtilitarioGeral.validaLista(gradeBase.ordenacaoSelecionada)) {
        parametrosConsulta.ordenacao.push('DataVencimento|ASC');
      }

      telaBase.carregando = true;
      const listaPaginada = await servicoTituloFinanceiroReceber.buscarTitulos(parametrosConsulta, gradeBase.filtrosAplicados);
      tituloFinanceiroBase.titulos = listaPaginada.dados;
      state.paginacaoApi = listaPaginada.metaData;
      telaBase.carregando = false;
      state.totalizadorSaldo = 0;
      tituloFinanceiroBase.titulos.forEach((t) => {
        state.totalizadorSaldo += t.valorTotal;
      });
    }

    async function atualizarTotalizadores() {
      if (refTotalizadores.value !== undefined) {
        await refTotalizadores.value.obterValores();
      }
    }

    async function atualizarSelecaoEmpresas() {
      telaBase.empresasSelecionadas = [] as number[];
      telaBase.empresasSelecionadas.push(state.empresaSelecionada);
      telaBase.permissaoDados = await filtrarPermissaoDadosUsuarioMultiEmpresas(telaBase.empresasSelecionadas);
      state.autorizacoes = await servicoSistema.obterVariasPermissoesAutorizacoesUsuario(['AUT_BAIXAS_CONTAS_RECEBER']);
      atualizarTotalizadores();
      state.carregandoCalendario = true;
      await buscarTitulos(true);
      await preencherEventos();
      state.carregandoCalendario = false;
    }

    async function aplicarFiltros() {
      state.paginacao.current = 1;
      await buscarTitulos(true);
    }

    function clickDia(dia: string) {
      const filtroVencimento = tituloFinanceiroBase.buscaAvancada.filtrosAdicionados.findIndex((filtro) => filtro.filtro.identificador === 'DataVencimento');
      if (filtroVencimento > -1) {
        tituloFinanceiroBase.buscaAvancada.filtrosAdicionados.splice(filtroVencimento, 1);
      }

      const filtro = {} as IFiltroGenericoAdicionado;
      tituloFinanceiroBase.buscaAvancada.chaveFiltrosAdicionados += 1;
      filtro.codigo = tituloFinanceiroBase.buscaAvancada.chaveFiltrosAdicionados;
      filtro.filtro = {} as IFiltroGenerico;
      filtro.filtro.identificador = 'DataVencimento';
      filtro.apresentacao = 'Data do Vencimento';
      filtro.filtro.tipoDado = 4;
      filtro.filtro.operador = EOperadorLogico.E;
      filtro.filtro.apresentacao = 'Data do Vencimento';
      filtro.filtro.condicao = 4;
      filtro.filtro.valores = [dia];
      filtro.detalhes = `igual a: ${UtilitarioData.aplicaFormatoData(dia)}`;
      tituloFinanceiroBase.buscaAvancada.filtrosAdicionados.push(filtro);
      aplicarFiltros();
    }

    function filtrarTitulosPelosTotalizadores(conceito: string) {
      tituloFinanceiroBase.buscaAvancada.filtrosAdicionados = [];
      gradeBase.codigosSelecionados = [];
      tituloFinanceiroBase.titulosBaixar = [];
      tituloFinanceiroBase.titulosExcluir = [];
      tituloFinanceiroBase.titulosEstornar = [];
      switch (conceito) {
        case 'VencimentoHoje':
          adicionaFiltroVencimentoHoje();
          break;
        case 'VencimentoMes':
          adicionaFiltroVencimentoEsteMes();
          break;
        case 'Atrasados':
          adicionaFiltroVencimentoAtrasados();
          break;
        case 'BaixadosHoje':
          adicionaFiltroBaixadosHoje();
          break;
        case 'BaixadosMes':
          adicionaFiltroBaixadosEsteMes();
          break;
        case 'BaixadosAtrasdos':
          adicionaFiltroBaixadosAtrasados();
          break;
        default:
      }
      aplicarFiltros();
    }

    async function alterarQuantidadeRegistroTotal(valor: any) {
      clearTimeout(state.timerRegistros);
      let totalRegistros = 100;
      state.timerRegistros = setTimeout(async () => {
        if (UtilitarioGeral.valorValido(valor)) {
          const limiteRegistros = UtilitarioMascara.removerMascaraNumero(valor);
          if (limiteRegistros > 0) {
            totalRegistros = limiteRegistros;
          }
        }
        state.paginacao.total = totalRegistros;
        state.totalRegistrosComMascara = UtilitarioMascara.mascararNumeroInteiro(totalRegistros);
        await buscarTitulos(true);
      }, 600);
    }

    async function mudarPagina(current: number, pageSize: number) {
      state.paginacao.current = current;
      state.paginacao.pageSize = pageSize;
      await buscarTitulos(true);
    }

    async function mostrarNovaPagina(current: number, pageSize: number) {
      state.paginacao.current = 1;
      state.paginacao.pageSize = pageSize;
      await buscarTitulos(true);
    }

    function baixarTitulos() {
      const token = UtilitarioGeral.gerarTokenUnico();
      storeFinanceiro.mutations.adicionarTitulosComFiltros(token, 'ContasReceber', 'Baixar titulos contas a Receber', tituloFinanceiroBase.titulosBaixar, tituloFinanceiroBase.buscaRapida, tituloFinanceiroBase.buscaAvancada);
      router.push({ name: 'BaixaContasReceber', query: { token } });
    }

    function verificaAcoesTitulosSelecionados() {
      tituloFinanceiroBase.titulosBaixar = [];
      tituloFinanceiroBase.titulosExcluir = [];
      tituloFinanceiroBase.titulosEstornar = [];
      state.qtdBoletosSelecionados = 0;
      state.qtdBoletosGeradosSelecionados = 0;
      state.qtdBoletosRegistradosSelecionados = 0;
      state.qtdGerarNfse = 0;
      state.totalSelecionado = 0;

      if (UtilitarioGeral.validaLista(gradeBase.codigosSelecionados)) {
        const titulosSelecionados = tituloFinanceiroBase.titulos.filter((c) => gradeBase.codigosSelecionados.includes(c.codigo));

        const titulosPendentes = titulosSelecionados.filter((c) => c.status === EStatusTituloFinanceiro.Pendente || c.status === EStatusTituloFinanceiro.BaixadoParcialmente);
        if (UtilitarioGeral.validaLista(titulosPendentes)) {
          tituloFinanceiroBase.titulosBaixar = titulosPendentes;
          tituloFinanceiroBase.titulosExcluir = UtilitarioGeral.instanciaObjetoLocal(titulosPendentes);
        }

        const titulosEstornar = titulosSelecionados.filter((c) => c.status === EStatusTituloFinanceiro.Baixado || c.status === EStatusTituloFinanceiro.BaixadoCartao || c.status === EStatusTituloFinanceiro.BaixadoCredito);
        if (UtilitarioGeral.validaLista(titulosEstornar)) {
          tituloFinanceiroBase.titulosEstornar = titulosEstornar;
        }

        titulosSelecionados.forEach((t) => {
          if (t.meioPagamento === EMeioPagamento.BoletoBancario) {
            if (t.tipoEmissaoBoleto === ETipoEmissaoBoleto.Terceiros || (t.tipoEmissaoBoleto === ETipoEmissaoBoleto.Propria && !UtilitarioGeral.valorValido(t.nossoNumero))) {
              state.qtdBoletosSelecionados += 1;
            } else if (t.situacaoBoleto === ESituacaoBoleto.Registrado) {
              state.qtdBoletosRegistradosSelecionados += 1;
            } else if (t.tipoEmissaoBoleto === ETipoEmissaoBoleto.Propria && t.situacaoBoleto === ESituacaoBoleto.Emitido) {
              state.qtdBoletosGeradosSelecionados += 1;
            }
          }
          state.totalSelecionado += t.valorTotal;
          if (t.numeroDocumentoFiscal === 0) {
            state.qtdGerarNfse += 1;
          }
        });
      }
    }

    const mudarSelecao = (selectedRowKeys: any) => {
      gradeBase.codigosSelecionados = selectedRowKeys;
      verificaAcoesTitulosSelecionados();
    };

    async function atualizarInformacoesTela() {
      await atualizarTotalizadores();
      await preencherEventos();
      await buscarTitulos(true);
      verificaAcoesTitulosSelecionados();
    }

    async function excluir(codigo: number) {
      const retorno = await servicoTituloFinanceiroReceber.excluir(codigo, state.empresaSelecionada);
      if (retorno.status === EStatusRetornoRequisicao.Sucesso) {
        apresentarMensagemSucesso(retorno.mensagem);
        await atualizarInformacoesTela();
      } else if (retorno.status === EStatusRetornoRequisicao.Alerta) {
        Modal.warning({
          title: 'Não foi possível concluir a exclusão!',
          content: retorno.mensagem,
        });
      }
    }

    async function confirmarExclusao(objeto: IDTOTituloFinanceiro) {
      Modal.confirm({
        title: 'Você confirma a exclusão da conta a receber',
        content: `${objeto.numeroTitulo} ?`,
        okText: 'Sim',
        okType: 'danger',
        cancelText: 'Não',
        autoFocusButton: null,
        centered: true,
        onOk: async () => { await excluir(objeto.codigo); },
      });
    }

    async function excluirEmMassa() {
      const retorno = await servicoTituloFinanceiroReceber.excluirEmMassa(state.empresaSelecionada, tituloFinanceiroBase.titulosExcluir.map((c) => c.codigo));
      if (retorno.status === EStatusRetornoRequisicao.Sucesso) {
        apresentarMensagemSucesso(retorno.mensagem);
        await atualizarInformacoesTela();
      } else if (retorno.status === EStatusRetornoRequisicao.Alerta) {
        Modal.warning({
          title: 'Não foi possível concluir a exclusão!',
          content: retorno.mensagem,
        });
      }
    }

    async function confirmarExclusaoEmMassa() {
      const numeroTitulos = tituloFinanceiroBase.titulosExcluir.map((t) => t.numeroTitulo);
      Modal.confirm({
        title: tituloFinanceiroBase.titulosExcluir.length > 1 ? 'Você confirma a exclusão dos títulos selecionados:' : 'Você confirma a exclusão do título selecionado:',
        content: `${numeroTitulos.join(', ')} ?`,
        okText: 'Sim',
        okType: 'danger',
        cancelText: 'Não',
        autoFocusButton: null,
        centered: true,
        onOk: async () => {
          await excluirEmMassa();
        },
      });
    }

    function abrirImportacaoExcel() {
      state.exibirImportacaoExcel = true;
    }

    function incluir() {
      state.operacaoLancamento = {} as ITelaOperacao;
      state.operacaoLancamento.tipoPermissaoDados = EPermissaoDados.Incluir;
      state.operacaoLancamento.listaPermissoesDados = telaBase.listaPermissoesDados;
      state.operacaoLancamento.codigoRegistro = 0;
      state.operacaoLancamento.empresaSelecionada = state.empresaSelecionada;
      state.exibirLancamento = true;
    }

    function editar(codigoRegistro: number) {
      state.operacaoLancamento = {} as ITelaOperacao;
      state.operacaoLancamento.tipoPermissaoDados = EPermissaoDados.Alterar;
      state.operacaoLancamento.listaPermissoesDados = telaBase.listaPermissoesDados;
      state.operacaoLancamento.codigoRegistro = codigoRegistro;
      state.operacaoLancamento.empresaSelecionada = state.empresaSelecionada;
      state.exibirLancamento = true;
    }

    async function imprimir(tipoArquivoRelatorio:ETipoArquivo, codigoRegistro?:number) {
      gradeBase.filtrosAplicados = [];

      tituloFinanceiroBase.buscaAvancada.filtrosAdicionados.forEach((item) => {
        gradeBase.filtrosAplicados.push(item.filtro);
      });
      const parametrosConsulta = {} as IParametrosConsultaTituloFinanceiro;
      parametrosConsulta.empresas = [state.empresaSelecionada];
      parametrosConsulta.numeroPagina = 1;
      parametrosConsulta.qtdeRegistrosPagina = 0;
      parametrosConsulta.qtdeRegistrosTotal = 0;
      parametrosConsulta.ordenacao = Array<string>();

      gradeBase.ordenacaoSelecionada.forEach((item) => {
        parametrosConsulta.ordenacao.push(`${item.identificador}|${item.ordem}`);
      });
      let codigosSelecionados: number[] = [];
      if (codigoRegistro !== undefined && codigoRegistro > 0) {
        codigosSelecionados.push(codigoRegistro);
      } else {
        codigosSelecionados = gradeBase.codigosSelecionados;
      }

      telaBase.carregando = true;
      const retornoRelatorio = await servicoTituloFinanceiroReceber.relatorioPadrao(tipoArquivoRelatorio, parametrosConsulta, gradeBase.filtrosAplicados, codigosSelecionados);
      telaBase.carregando = false;
      if (retornoRelatorio.status === EStatusRetornoRequisicao.Sucesso) {
        if (tipoArquivoRelatorio === ETipoArquivo.PDF) {
          state.arquivosPdf = [{ nome: '', link: retornoRelatorio.link } as IArquivoPdf];
        } else {
          UtilitarioGeral.downloadArquivo(retornoRelatorio.link);
        }
      } else if (retornoRelatorio.status === EStatusRetornoRequisicao.Alerta) {
        apresentarMensagemAlerta(retornoRelatorio.mensagem);
      } else if (retornoRelatorio.status === EStatusRetornoRequisicao.Erro) {
        apresentarMensagemErro(retornoRelatorio.mensagem);
      }
    }

    async function carregarPreferencias() {
      telaBase.preferencias = await new ServicoSistema().obterPreferenciaRecurso(telaBase.identificadorRecurso, telaBase.empresasSelecionadas);
      if (telaBase.preferencias !== null) {
        tituloFinanceiroBase.buscaAvancada.filtrosAdicionados = carregarPreferenciasFiltros(telaBase.preferencias);
        telaBase.ordenacaoSelecionada = carregarPreferenciasOrdenacao(telaBase.preferencias);
        gradeBase.colunas = carregarPreferenciasGrade(gradeBase.colunasPadrao, telaBase.preferencias);
      }
    }

    onBeforeMount(async () => {
      state.empresaSelecionada = storeSistema.getters.codigoEmpresaOperacao();
      gradeBase.layoutMobile = storeSistema.state.layoutMobile;
      preencherDadosRota();
      await obterPermissoes(ETipoPermissao.Dados);
      await preencherEmpresasDisponiveis();
      await defineEmpresasSelecionadasComPermissao();
      tituloFinanceiroBase.dataAtual = await servicoSistema.obterDataAtual();
      tituloFinanceiroBase.dataCalendario = tituloFinanceiroBase.dataAtual;
      state.autorizacoes = await servicoSistema.obterVariasPermissoesAutorizacoesUsuario(['AUT_BAIXAS_CONTAS_RECEBER']);
      telaBase.permissaoDados = await filtrarPermissaoDadosUsuarioMultiEmpresas([state.empresaSelecionada]);
      verificaConceitoParaApresentarEmpresas();
      telaBase.propriedadesConsulta = await servicoTituloFinanceiroReceber.obterPropriedadesConsulta(state.empresaSelecionada);
      if (telaBase.propriedadesConsulta.length > 0) {
        tituloFinanceiroBase.buscaAvancada.filtros = telaBase.propriedadesConsulta.filter((item) => item.filtro === true);
        tituloFinanceiroBase.buscaAvancada.ordenacao = telaBase.propriedadesConsulta.filter((item) => item.ordenacao === true);
      }
      tituloFinanceiroBase.buscaAvancada.filtrosAdicionados = [];
      tituloFinanceiroBase.buscaAvancada.chaveFiltrosAdicionados = 0;
      preencherColunas();
      await carregarPreferencias();

      if (route.query.token !== undefined) {
        const tokenStore = String(route.query.token);
        if (UtilitarioGeral.valorValido(tokenStore)) {
          const tituloFinanceiroStore = storeFinanceiro.getters.obterTituloFinanceiroStore(tokenStore);
          if (UtilitarioGeral.objetoValido(tituloFinanceiroStore)) {
            tituloFinanceiroBase.buscaRapida = tituloFinanceiroStore.buscaRapida;
            if (UtilitarioGeral.objetoValido(tituloFinanceiroStore.buscaAvancada)) {
              tituloFinanceiroBase.buscaAvancada = tituloFinanceiroStore.buscaAvancada;
            }
          }
        }
      }

      state.paginacao.total = 100;
      state.paginacao.showSizeChanger = true;
      state.paginacao.pageSize = 100;
      state.paginacao.pageSizeOptions = ['10', '50', '100', '500', '1000'];
      state.carregandoCalendario = true;
      if (UtilitarioGeral.validaLista(tituloFinanceiroBase.buscaAvancada.filtrosAdicionados)) {
        aplicarFiltros();
      } else {
        filtrarTitulosPelosTotalizadores('VencimentoMes');
      }

      await preencherEventos();
      state.carregandoCalendario = false;
    });

    function verificarAutorizacao(identificadorAutorizacao: string) {
      return servicoSistema.verificaAutorizacao(state.empresaSelecionada, identificadorAutorizacao, state.autorizacoes);
    }

    function textoCodigosSelecionados():string {
      if (gradeBase.codigosSelecionados.length === 1) { return '1 - Registro selecionado'; }

      if (gradeBase.codigosSelecionados.length > 1) { return `${gradeBase.codigosSelecionados.length} - Registros selecionados`; }

      return '';
    }

    async function enviarEmailBoletos() {
      console.log(gradeBase.codigosSelecionados, 3);
      const titulos = [] as number[];
      const titulosSelecionados = tituloFinanceiroBase.titulos.filter((c) => gradeBase.codigosSelecionados.includes(c.codigo));

      titulosSelecionados.forEach((t) => {
        if (t.meioPagamento === EMeioPagamento.BoletoBancario && t.tipoEmissaoBoleto === ETipoEmissaoBoleto.Propria) {
          titulos.push(t.codigo);
        }
      });

      const retorno = await servicoBoletos.enviarEmailBoletos(state.empresaSelecionada, titulos);
      if (retorno.status === EStatusRetornoRequisicao.Sucesso) {
        if (titulos.length > 1) {
          apresentarMensagemSucesso('Boleto enviado com sucesso!');
        } else {
          apresentarMensagemSucesso('Boletos enviados com sucesso!');
        }
        gradeBase.codigosSelecionados = [] as number[];
      } else {
        apresentarMensagemErro(retorno.mensagem);
      }
    }

    async function confirmarEnviarEmailBoletos(enviarDireto?: boolean) {
      if (!enviarDireto) {
        console.log('Entrou no if enviar direto');
        if (!state.gerandoBoletos) return;
        state.gerandoBoletos = false;
      }

      if (state.qtdBoletosGeradosSelecionados === 0 && state.qtdBoletosRegistradosSelecionados === 0) {
        apresentarMensagemAlerta('Nenhum dos títulos selecionados são do tipo boleto!');
        return;
      }
      const qtdSelecionados = state.qtdBoletosGeradosSelecionados + state.qtdBoletosRegistradosSelecionados;

      Modal.confirm({
        title: qtdSelecionados > 1 ? 'Deseja enviar o e-mail de cobrança dos títulos selecionados?' : 'Deseja enviar o e-mail de cobrança do título selecionado?',
        okText: 'Sim',
        okType: 'danger',
        cancelText: 'Não',
        autoFocusButton: null,
        centered: true,
        onOk: async () => {
          await enviarEmailBoletos();
        },
      });
    }

    async function gerarBoletos(substituir: boolean) {
      const titulos = [] as number[];
      const titulosSelecionados = tituloFinanceiroBase.titulos.filter((c) => gradeBase.codigosSelecionados.includes(c.codigo));

      titulosSelecionados.forEach((t) => {
        if (t.meioPagamento === EMeioPagamento.BoletoBancario) {
          titulos.push(t.codigo);
        }
      });

      const retorno = await servicoBoletos.gerarBoletos(state.empresaSelecionada, substituir, titulos);
      const arquivos = [] as IArquivoPdf[];
      retorno.forEach((r, index) => {
        if (r.status === EStatusRetornoRequisicao.Sucesso) {
          state.gerandoBoletos = true;
          const arquivo = {} as IArquivoPdf;
          arquivo.nome = `Arquivo ${(index + 1)}`;
          arquivo.link = r.mensagem;
          arquivos.push(arquivo);
        } else {
          apresentarMensagemErro(r.mensagem);
        }
      });
      if (arquivos.length > 0) {
        state.arquivosPdf = arquivos;
      }
    }

    async function confirmarGerarBoletos(substituir: boolean) {
      if (state.qtdBoletosSelecionados === 0 && state.qtdBoletosGeradosSelecionados === 0 && state.qtdBoletosRegistradosSelecionados === 0) {
        apresentarMensagemAlerta('Nenhum dos títulos selecionados são do tipo boleto!');
        return;
      }

      let titulo = '';
      const qtdSelecionados = state.qtdBoletosGeradosSelecionados + state.qtdBoletosSelecionados;
      if (substituir) {
        if (qtdSelecionados > 1) {
          titulo = 'Realmente deseja gerar boletos para os títulos selecionados?';
        } else {
          titulo = 'Realmente deseja gerar boleto para o título selecionado?';
        }
      } else if (qtdSelecionados > 1) {
        titulo = 'Realmente deseja reimprimir os boletos dos títulos selecionados?';
      } else {
        titulo = 'Realmente deseja reimprimir o boleto do título selecionado?';
      }

      Modal.confirm({
        title: titulo,
        okText: 'Sim',
        okType: 'danger',
        cancelText: 'Não',
        autoFocusButton: null,
        centered: true,
        onOk: async () => {
          await gerarBoletos(substituir);
          await buscarTitulos(false);
          if (substituir) {
            verificaAcoesTitulosSelecionados();
          }
        },
      });
    }

    async function confirmarNegativcao(negativar: boolean) {
      if (state.qtdBoletosSelecionados) {
        apresentarMensagemAlerta('Nenhum boleto selecionado!');
        return;
      }
      if (state.qtdBoletosRegistradosSelecionados === 0) {
        if (negativar) {
          apresentarMensagemAlerta('Nenhum dos boletos selecionados podem ser negativados!');
        } else {
          apresentarMensagemAlerta('Nenhum dos boletos selecionados podem ser protestados!');
        }
        return;
      }

      let titulo = '';
      const qtdSelecionados = state.qtdBoletosRegistradosSelecionados;
      if (negativar) {
        if (qtdSelecionados > 1) {
          titulo = 'Negativar os títulos selecionados?';
        } else {
          titulo = 'Negativar o título selecionado?';
        }
      } else if (qtdSelecionados > 1) {
        titulo = 'Protestar os títulos selecionados?';
      } else {
        titulo = 'Protestar o título selecionado?';
      }
      const titulosSelecionados = tituloFinanceiroBase.titulos.filter((c) => gradeBase.codigosSelecionados.includes(c.codigo));
      const titulos = [] as number[];
      titulosSelecionados.forEach((t) => {
        if (t.meioPagamento === EMeioPagamento.BoletoBancario && t.situacaoBoleto === ESituacaoBoleto.Registrado) {
          titulos.push(t.codigo);
        }
      });

      Modal.confirm({
        title: titulo,
        okText: 'Sim',
        okType: 'danger',
        cancelText: 'Não',
        autoFocusButton: null,
        centered: true,
        onOk: async () => {
          await servicoTituloFinanceiroReceber.negativarProtestar(state.empresaSelecionada, negativar, titulos);
          await buscarTitulos(true);
        },
      });
    }

    async function imprimirRemessa(primeiroTitulo: number) {
      const retornoRelatorio = await servicoBoletos.gerarRelatorioRemessa(state.empresaSelecionada, primeiroTitulo);
      if (retornoRelatorio.status === EStatusRetornoRequisicao.Sucesso) {
        state.arquivosPdf = [{ nome: '', link: retornoRelatorio.link } as IArquivoPdf];
      } else if (retornoRelatorio.status === EStatusRetornoRequisicao.Alerta) {
        apresentarMensagemAlerta(retornoRelatorio.mensagem);
      } else if (retornoRelatorio.status === EStatusRetornoRequisicao.Erro) {
        apresentarMensagemErro(retornoRelatorio.mensagem);
      }
    }

    async function gerarRemessa(conta: number, titulos: number[]) {
      const retorno = await servicoBoletos.gerarRemessa(state.empresaSelecionada, conta, titulos);
      if (retorno.status === EStatusRetornoRequisicao.Sucesso) {
        UtilitarioGeral.downloadArquivo(retorno.mensagem);
        await imprimirRemessa(titulos[0]);
      } else {
        apresentarMensagemErro(retorno.mensagem);
      }
    }

    async function gerarRemessaContas() {
      const contas = [] as number[];
      const titulosSelecionados = tituloFinanceiroBase.titulos.filter((c) => gradeBase.codigosSelecionados.includes(c.codigo));

      titulosSelecionados.forEach((t) => {
        if (t.meioPagamento === EMeioPagamento.BoletoBancario) {
          const index = contas.findIndex((c) => c === t.codigoConta);
          if (index === -1) {
            contas.push(t.codigoConta);
          }
        }
      });

      if (contas.length === 1) {
        const titulosConta = titulosSelecionados.filter((t) => t.codigoConta === contas[0]);
        const titulos = [] as number[];

        titulosConta.forEach((t) => {
          titulos.push(t.codigo);
        });
        await gerarRemessa(contas[0], titulos);
      } else {
        contas.forEach((c) => {
          const titulosConta = titulosSelecionados.filter((t) => t.codigoConta === c);
          const titulos = [] as number[];

          titulosConta.forEach((t) => {
            titulos.push(t.codigo);
          });
          gerarRemessa(c, titulos);
        });
      }
      gradeBase.codigosSelecionados = [] as number[];
      buscarTitulos(true);
    }

    async function apresentarEmissaoNFSe() {
      state.titulosNFSe = tituloFinanceiroBase.titulos.filter((c) => gradeBase.codigosSelecionados.includes(c.codigo) && c.numeroDocumentoFiscal === 0);
      state.exibirEmissaoNFSe = true;
    }

    async function confirmarGerarRemessa() {
      if (state.qtdBoletosGeradosSelecionados === 0 && state.qtdBoletosRegistradosSelecionados === 0) {
        apresentarMensagemAlerta('Nenhum dos títulos selecionados são do tipo boleto!');
        return;
      }
      const qtdSelecionados = state.qtdBoletosGeradosSelecionados + state.qtdBoletosRegistradosSelecionados;

      Modal.confirm({
        title: qtdSelecionados > 1 ? 'Realmente deseja gerar remessa para os boletos dos títulos selecionados?' : 'Realmente deseja gerar remessa para o boleto do título selecionado?',
        okText: 'Sim',
        okType: 'danger',
        cancelText: 'Não',
        autoFocusButton: null,
        centered: true,
        onOk: async () => {
          await gerarRemessaContas();
        },
      });
    }

    watch(() => storeSistema.state.layoutMobile, () => {
      gradeBase.layoutMobile = storeSistema.state.layoutMobile;
      preencherColunas();
    });

    function apresentarPersonalizarColuna() {
      state.exibirPersonalizarColunas = !state.exibirPersonalizarColunas;
    }

    return {
      tituloFinanceiroBase,
      state,
      props,
      window,
      ETipoTituloFinanceiro,
      EStatusTituloFinanceiro,
      EVinculoEmpresa,
      ETipoArquivo,
      ECustomRenderRow,
      ETipoEmissaoBoleto,
      ESituacaoBoleto,
      UtilitarioGeral,
      UtilitarioMascara,
      UtilitarioData,
      UtilitarioDispositivo,
      storeSistema,
      telaBase,
      gradeBase,
      Totalizador,
      incluir,
      editar,
      confirmarExclusao,
      confirmarExclusaoEmMassa,
      aplicarFiltros,
      abrirImportacaoExcel,
      mudarPagina,
      mostrarNovaPagina,
      alterarQuantidadeRegistroTotal,
      buscarTitulos,
      imprimir,
      obterClasseStatus,
      mudarSelecao,
      atualizarSelecaoEmpresas,
      adicionarAtalho,
      removerAtalho,
      clickDia,
      preencherEventos,
      refTotalizadores,
      baixarTitulos,
      filtrarTitulosPelosTotalizadores,
      obtemValorTitulo,
      atualizarInformacoesTela,
      verificarAutorizacao,
      textoCodigosSelecionados,
      salvarPreferenciasFiltros,
      salvarPreferenciasOrdenacao,
      confirmarGerarBoletos,
      confirmarGerarRemessa,
      confirmarEnviarEmailBoletos,
      apresentarEmissaoNFSe,
      apresentarPersonalizarColuna,
      salvarPreferenciasGrade,
      redefinirPersonalizacaoColunas,
      confirmarNegativcao,
    };
  },
});
